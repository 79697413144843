import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAuth } from 'firebase/auth';

export const environment = {
    production: false,
    useEmulators:false,
    name: 'inpraxi staging',
    feedbackEmail: '',
    devBugsnagEnabled: false,
    region: 'europe-west2',
    backendUrl: 'https://europe-west2-inpraxi-staging.cloudfunctions.net',
    firebase: {
        apiKey: 'AIzaSyBck42bTbm0RA5SCyRM-lAd1GZSJ2Z5ZfM',
        authDomain: 'inpraxi-staging.firebaseapp.com',
        databaseURL: 'https://inpraxi-staging.firebaseio.com',
        projectId: 'inpraxi-staging',
        storageBucket: 'inpraxi-staging.appspot.com',
        messagingSenderId: '31660255459',
        appId: '1:31660255459:web:b74b6ad5957b0b338abdd6'
    }
};

export const app = initializeApp(environment.firebase);
export const functions = getFunctions(app, environment.region);
export const firestore = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);